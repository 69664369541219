import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'global-search',
  template: `<label for="search">
  <input type="text"
         id="search"
         class="ngx-table__header-search"
         #input
         (input)="globalUpdate.emit({value: input.value})"
         placeholder="Search"/>
</label>
`
})

/**
 * From version 5.0 GlobalSearchComponent will be deprecated,
 * and from version 6.0 moved to GlobalSearchComponent plugin
 */
export class GlobalSearchComponent {
  @Output() globalUpdate = new EventEmitter();
}
