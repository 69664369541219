import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit,
} from '@angular/core';
import { ConfigService } from '../../services/config-service';

@Component({
  selector: 'pagination',
  template: `<div class="ngx-pagination-wrapper"
     [class.ngx-table__table--dark-pagination-wrapper]="config.tableLayout.theme === 'dark'">
  <div class="ngx-pagination-steps">
    <pagination-controls
      [class.ngx-pagination-style]="config.tableLayout.theme !== 'dark'"
      [class.ngx-table__table--dark-pagination]="config.tableLayout.theme === 'dark'"
      id="pagination-controls"
      [id]="id"
      [maxSize]="5"
      [previousLabel]="''"
      [nextLabel]="''"
      (pageChange)="onPageChange($event)">
    </pagination-controls>
  </div>
  <div class="ngx-pagination-range"
       [class.ngx-table__table--dark-pagination-range]="config.tableLayout.theme === 'dark'"
       *ngIf="config.paginationRangeEnabled">
    <div class="ngx-dropdown ngx-pagination-range-dropdown"
         [class.ngx-active]="showRange"
         id="rowAmount">
      <div class="ngx-btn-group">
        <div class="ngx-pagination-range-dropdown-button"
             (click)="showRange = !showRange">
          {{limit}} <i class="ngx-icon ngx-icon-arrow-down"></i>
        </div>
        <ul class="ngx-menu">
          <li class="ngx-pagination-range-dropdown-button-item"
              (click)="changeLimit(limit)"
              *ngFor="let limit of ranges">
            <span>{{limit}}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>
`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PaginationComponent {
  @Input() pagination;
  @Input() config;
  @Input() id;
  @Output() updateRange = new EventEmitter();
  public ranges = [5, 10, 25, 50, 100];
  public limit = ConfigService.config.rows;
  public showRange = false;

  onPageChange($event) {
    this.updateRange.emit({
      page: $event,
      limit: this.limit,
    });
  }

  changeLimit(limit): void {
    this.showRange = !this.showRange;
    this.limit = limit;
    this.updateRange.emit({
      page: 1,
      limit: limit,
    });
  }
}
