import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';

import { ConfigService } from '../../services/config-service';
import { Event } from '../../model/event.enum';
import { Config } from '../../model/config';
import { flatMap, groupBy, reduce } from 'rxjs/operators';
import { from } from 'rxjs';
import { Columns } from '../../model/columns';
import { UtilsService } from '../../services/utils-service';

@Component({
  selector: 'ngx-table',
  providers: [ConfigService, UtilsService],
  template: `<div class="ngx-container">
  <div class="ngx-global-search">
    <global-search
      *ngIf="config.globalSearchEnabled"
      (globalUpdate)="onGlobalSearch($event)">
    </global-search>
  </div>
  <table id="table"
         class="ngx-table"
         [class.ngx-table__table--tiny]="config.tableLayout.style === 'tiny'"
         [class.ngx-table__table--normal]="config.tableLayout.style === 'normal'"
         [class.ngx-table__table--big]="config.tableLayout.style === 'big'"
         [class.ngx-table__table--borderless]="config.tableLayout.borderless"
         [class.ngx-table__table--dark]="config.tableLayout.theme === 'dark'"
         [class.ngx-table__table--hoverable]="config.tableLayout.hover"
         [class.ngx-table__table--striped]="config.tableLayout.striped"
         [class.ngx-table__horizontal-scroll]="config.horizontalScroll && !isLoading">
    <thead>
    <tr class="ngx-table__header" *ngIf="config.headerEnabled">
      <th *ngIf="config.checkboxes" width="3%">
        <label class="ngx-form-checkbox">
          <input type="checkbox" (change)="onSelectAll()">
          <i class="ngx-form-icon" id="selectAllCheckbox"></i>
        </label>
      </th>
      <ng-container *ngFor="let column of columns;let colIndex = index">
        <th class="ngx-table__header-cell"
            #th
            [attr.width]="getColumnWidth(column)"
            (mousedown)="onMouseDown($event, th)"
            (mouseup)="onMouseUp($event)"
            (mousemove)="onMouseMove($event)">
          <div (click)="orderBy(column['key'])">
            <div class="ngx-table__header-title">{{ column['title'] }}<span>&nbsp;</span>
              <i *ngIf="sortBy.key === column['key'] && sortBy.order==='asc'"
                 [style.display]="config.orderEnabled?'':'none' "
                 class="ngx-icon ngx-icon-arrow-up">
              </i>
              <i *ngIf="sortBy.key === column['key'] && sortBy.order==='desc'"
                 [style.display]="config.orderEnabled?'':'none' "
                 class="ngx-icon ngx-icon-arrow-down">
              </i>
            </div>
          </div>
          <div class="ngx-table__column-resizer" *ngIf="config.resizeColumn"></div>
        </th>
      </ng-container>
      <th *ngIf="config.additionalActions || config.detailsTemplate || config.collapseAllRows || config.groupRows"
          class="ngx-table__header-cell-additional-actions">
        <div class="ngx-dropdown ngx-active"
             *ngIf="config.additionalActions"
             [class.ngx-active]="menuActive">
          <a class="ngx-btn ngx-btn-link" (click)="menuActive = !menuActive">
            <span class="ngx-icon ngx-icon-menu"></span>
          </a>
          <ul class="ngx-menu ngx-table__table-menu">
            <li class="ngx-menu-item">
              <app-csv-export [data]="data"
                              *ngIf="config.exportEnabled">
              </app-csv-export>
            </li>
          </ul>
        </div>
      </th>
    </tr>
    <tr *ngIf="config.searchEnabled"
        class="ngx-table__sort-header">
      <th *ngIf="config.checkboxes"></th>
      <ng-container *ngFor="let column of columns; let colIndex = index">
        <th>
          <table-header (update)="onSearch($event)" [column]="column"></table-header>
        </th>
      </ng-container>
      <th *ngIf="config.additionalActions || config.detailsTemplate"></th>
    </tr>
    </thead>
    <tbody *ngIf="data && !isLoading  && !config.draggable">
    <ng-container *ngIf="rowTemplate">
      <ng-container *ngFor="let row of data | sort:sortBy | search:term | global:globalSearchTerm | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count, id: id };
              let rowIndex = index">
        <tr
          (click)="onClick($event, row, '', null, rowIndex)"
          (dblclick)="onDoubleClick($event, row, '', null, rowIndex)"
          [class.ngx-table__table-row--selected]="rowIndex == selectedRow && !config.selectCell">
          <ng-container
            [ngTemplateOutlet]="rowTemplate"
            [ngTemplateOutletContext]="{ $implicit: row, index: rowIndex }">
          </ng-container>
          <td *ngIf="config.detailsTemplate">
            <span class="ngx-icon"
                  [class.ngx-icon-arrow-down]="isRowCollapsed(rowIndex)"
                  [class.ngx-icon-arrow-right]="!isRowCollapsed(rowIndex)"
                  (click)="collapseRow(rowIndex)">
            </span>
          </td>
        </tr>
        <tr
          *ngIf="(config.detailsTemplate && selectedDetailsTemplateRowId.has(rowIndex)) || config.collapseAllRows">
          <td [attr.colspan]="columns.length + 1">
            <ng-container
              [ngTemplateOutlet]="detailsTemplate"
              [ngTemplateOutletContext]="{ $implicit: row, index: rowIndex  }">
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!rowTemplate && !config.groupRows">
      <ng-container
        *ngFor="let row of data | sort:sortBy | search:term | global:globalSearchTerm | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count, id: id };
                  let rowIndex = index">
        <tr [class.ngx-table__table-row--selected]="rowIndex == selectedRow && !config.selectCell">
          <td *ngIf="config.checkboxes">
            <label class="ngx-form-checkbox">
              <input type="checkbox"
                     id="checkbox-{{rowIndex}}"
                     [checked]="isSelected"
                     (change)="onCheckboxSelect($event.target.value, row, rowIndex)">
              <i class="ngx-form-icon"></i>
            </label>
          </td>
          <ng-container *ngFor="let column of columns; let colIndex = index">
            <td (click)="onClick($event, row, column['key'], colIndex, rowIndex)"
                (dblclick)="onDoubleClick($event, row, column['key'], colIndex, rowIndex)"
                [class.ngx-table__table-col--selected]="colIndex == selectedCol && !config.selectCell"
                [class.ngx-table__table-cell--selected]="colIndex == selectedCol && rowIndex == selectedRow && !config.selectCol && !config.selectRow"
            >
              <div *ngIf="!column.cellTemplate">{{ row | render:column.key }}</div>
              <ng-container
                *ngIf="column.cellTemplate"
                [ngTemplateOutlet]="column.cellTemplate"
                [ngTemplateOutletContext]="{ $implicit: row }">
              </ng-container>
            </td>
          </ng-container>
          <td *ngIf="config.additionalActions || config.detailsTemplate">
              <span class="ngx-icon"
                    [class.ngx-icon-arrow-down]="isRowCollapsed(rowIndex)"
                    [class.ngx-icon-arrow-right]="!isRowCollapsed(rowIndex)"
                    (click)="collapseRow(rowIndex)">
              </span>
          </td>
        </tr>
        <tr
          *ngIf="(config.detailsTemplate && selectedDetailsTemplateRowId.has(rowIndex)) || config.collapseAllRows">
          <td *ngIf="config.checkboxes"></td>
          <td [attr.colspan]="columns.length + 1">
            <ng-container
              [ngTemplateOutlet]="detailsTemplate"
              [ngTemplateOutletContext]="{ $implicit: row, index: rowIndex }">
            </ng-container>
          </td>
        </tr>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!rowTemplate && config.groupRows">
      <ng-container
        *ngFor="let group of grouped; let rowIndex = index">
        <tr>
          <td [attr.colspan]="columns.length">
            <div>{{group[0][groupRowsBy]}} ({{group.length}})</div>
          </td>
          <td>
            <span class="ngx-icon"
                  [class.ngx-icon-arrow-down]="isRowCollapsed(rowIndex)"
                  [class.ngx-icon-arrow-right]="!isRowCollapsed(rowIndex)"
                  (click)="collapseRow(rowIndex)">
            </span>
          </td>
        </tr>
        <ng-container *ngIf="selectedDetailsTemplateRowId.has(rowIndex)">
          <tr *ngFor="let row of group">
            <td *ngFor="let column of columns">
              {{ row | render:column.key }}
              <!-- TODO allow users to add groupRowsTemplateRef -->
            </td>
            <td></td>
          </tr>
        </ng-container>
      </ng-container>
    </ng-container>
    </tbody>
    <tbody *ngIf="data && !config.isLoading && config.draggable"
           class="ngx-dnd-container"
           (drag)="onRowDrag($event)"
           (drop)="onRowDrop($event)"
           ngxDroppable>
    <ng-container *ngIf="!rowTemplate && !config.groupRows">
      <ng-container
        *ngFor="let row of data | sort:sortBy | search:term | global:globalSearchTerm | paginate: { itemsPerPage: limit, currentPage: page, totalItems: count, id: id };
                  let rowIndex = index">
        <tr [class.ngx-table__table-row--selected]="rowIndex == selectedRow && !config.selectCell"
            class="ngx-dnd-item"
            ngxDraggable>
          <td *ngIf="config.checkboxes">
            <label class="ngx-form-checkbox">
              <input type="checkbox"
                     id="checkbox-draggable-{{rowIndex}}"
                     [checked]="isSelected"
                     (change)="onCheckboxSelect($event.target.value, row, rowIndex)">
              <i class="ngx-form-icon"></i>
            </label>
          </td>
          <ng-container *ngFor="let column of columns; let colIndex = index">
            <td (click)="onClick($event, row, column['key'], colIndex, rowIndex)"
                (dblclick)="onDoubleClick($event, row, column['key'], colIndex, rowIndex)"
                [class.ngx-table__table-col--selected]="colIndex == selectedCol && !config.selectCell"
                [class.ngx-table__table-cell--selected]="colIndex == selectedCol && rowIndex == selectedRow && !config.selectCol && !config.selectRow"
            >
              <div>{{ row | render:column.key }}</div>
            </td>
          </ng-container>
        </tr>
      </ng-container>
    </ng-container>
    </tbody>
    <tbody *ngIf="!data || (data && data.length < 1)">
    <tr class="ngx-table__body-empty">
      <td [attr.colspan]="columns && columns.length + 1">
        <div class="ngx-table__table-no-results">
          No results
        </div>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="isLoading">
    <tr class="ngx-table__body-loading">
      <td [attr.colspan]="columns && columns.length + 1">
        <div [style.height]="loadingHeight"
             class="ngx-table__table-loader-wrapper">
          <div class="ngx-table__table-loader"></div>
        </div>
      </td>
    </tr>
    </tbody>
    <tfoot *ngIf="summaryTemplate">
    <tr>
      <ng-container
        [ngTemplateOutlet]="summaryTemplate"
        [ngTemplateOutletContext]="{ total: data.length, limit: limit, page: page  }">
      </ng-container>
    </tr>
    </tfoot>
  </table>
  <pagination
    id="pagination"
    *ngIf="config.paginationEnabled"
    [id]="id"
    [config]="config"
    [pagination]="pagination"
    (updateRange)="onPagination($event)">
  </pagination>
</div>
`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseComponent implements OnInit, OnChanges, AfterViewInit {
  public selectedRow: number;
  public selectedCol: number;
  public term;
  public config: Config;
  public globalSearchTerm;
  grouped = [];
  menuActive = false;
  isSelected = false;
  page = 1;
  count = null;
  limit;
  sortBy = {
    key: '',
    order: 'asc',
  };
  selectedDetailsTemplateRowId = new Set();
  id;
  th = undefined;
  startOffset;
  loadingHeight = '30px';
  @Input() configuration: Config;
  @Input() data: Array<Object>;
  @Input() pagination;
  @Input() groupRowsBy;
  @Input() detailsTemplate: TemplateRef<any>;
  @Input() summaryTemplate: TemplateRef<any>;
  @Input() columns: Columns[];
  @Output() event = new EventEmitter();
  @ContentChild(TemplateRef) public rowTemplate: TemplateRef<any>;

  constructor(private cdr: ChangeDetectorRef) {
    this.id = UtilsService.randomId();
  }

  ngOnInit() {
    if (!this.columns) {
      console.error('[columns] property required!');
    }
    if (this.configuration) {
      ConfigService.config = this.configuration;
    }
    this.config = ConfigService.config;
    this.limit = this.config.rows;
    if (this.groupRowsBy) {
      this.doGroupRows();
    }
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    const data: SimpleChange = changes.data;
    const pagination: SimpleChange = changes.pagination;
    const configuration: SimpleChange = changes.configuration;
    const groupRowsBy: SimpleChange = changes.groupRowsBy;
    if (data && data.currentValue) {
      this.data = [...data.currentValue];
    }
    if (pagination && pagination.currentValue) {
      this.count = pagination.currentValue.count;
    }
    if (configuration && configuration.currentValue) {
      this.config = configuration.currentValue;
      this.cdr.markForCheck();
    }
    if (groupRowsBy && groupRowsBy.currentValue) {
      this.doGroupRows();
    }
  }

  orderBy(key: string): void {
    if (!ConfigService.config.orderEnabled) {
      return;
    }
    this.sortBy.key = key;
    if (this.sortBy.order === 'asc') {
      this.sortBy.order = 'desc';
    } else {
      this.sortBy.order = 'asc';
    }
    const value = {
      key,
      order: this.sortBy.order,
    };
    if (!ConfigService.config.serverPagination) {
      this.data = [...this.data];
    }
    this.emitEvent(Event.onOrder, value);
  }

  onClick($event: object, row: object, key: string | number | boolean, colIndex: number, rowIndex: number): void {
    if (ConfigService.config.selectRow) {
      this.selectedRow = rowIndex;
    }
    if (ConfigService.config.selectCol) {
      this.selectedCol = colIndex;
    }
    if (ConfigService.config.selectCell) {
      this.selectedRow = rowIndex;
      this.selectedCol = colIndex;
    }
    if (ConfigService.config.clickEvent) {
      const value = {
        event: $event,
        row: row,
        key: key,
        rowId: rowIndex,
        colId: colIndex,
      };
      this.emitEvent(Event.onClick, value);
    }
  }

  onDoubleClick($event: object, row: object, key: string | number | boolean, colIndex: number, rowIndex: number): void {
    const value = {
      event: $event,
      row: row,
      key: key,
      rowId: rowIndex,
      colId: colIndex,
    };
    this.emitEvent(Event.onDoubleClick, value);
  }

  onCheckboxSelect($event: object, row: object, rowIndex: number): void {
    const value = {
      event: $event,
      row: row,
      rowId: rowIndex,
    };
    this.emitEvent(Event.onCheckboxSelect, value);
  }

  onSelectAll() {
    this.isSelected = !this.isSelected;
    this.emitEvent(Event.onSelectAll, this.isSelected);
  }

  onSearch($event): void {
    if (!ConfigService.config.serverPagination) {
      this.term = $event;
    }
    this.emitEvent(Event.onSearch, $event);
  }

  onGlobalSearch($event): void {
    if (!ConfigService.config.serverPagination) {
      this.globalSearchTerm = $event;
    }
    this.emitEvent(Event.onGlobalSearch, $event);
  }

  onPagination($event): void {
    this.page = $event.page;
    this.limit = $event.limit;
    this.emitEvent(Event.onPagination, $event);
  }

  private emitEvent(event, value: Object): void {
    this.event.emit({ event: Event[event], value });
    if (this.config.logger) {
      console.log({ event: Event[event], value });
    }
  }

  collapseRow(rowIndex: number): void {
    if (this.selectedDetailsTemplateRowId.has(rowIndex)) {
      this.selectedDetailsTemplateRowId.delete(rowIndex);
      this.emitEvent(Event.onRowCollapsedHide, rowIndex);
    } else {
      this.selectedDetailsTemplateRowId.add(rowIndex);
      this.emitEvent(Event.onRowCollapsedShow, rowIndex);
    }
  }

  private doGroupRows() {
    this.grouped = [];
    from(this.data).pipe(
      groupBy(row => row[this.groupRowsBy]),
      flatMap(group => group.pipe(
        reduce((acc: Array<Object>, curr) => [...acc, curr], []),
      )),
    ).subscribe(row => this.grouped.push(row));
  }

  isRowCollapsed(rowIndex: number): boolean {
    if (this.config.collapseAllRows) {
      return true;
    }
    return this.selectedDetailsTemplateRowId.has(rowIndex);
  }

  onMouseDown(event, th) {
    if (!this.config.resizeColumn) {
      return;
    }
    this.th = th;
    this.startOffset = th.offsetWidth - event.pageX;
    this.emitEvent(Event.onColumnResizeMouseDown, event);
  }

  onMouseMove(event) {
    if (!this.config.resizeColumn) {
      return;
    }
    if (this.th) {
      this.th.style.width = this.startOffset + event.pageX + 'px';
      this.th.style.cursor = 'col-resize';
      this.th.style['user-select'] = 'none';
    }
  }

  onMouseUp(event) {
    if (!this.config.resizeColumn) {
      return;
    }
    this.emitEvent(Event.onColumnResizeMouseUp, event);
    this.th.style.cursor = 'default';
    this.th = undefined;
  }

  get isLoading(): boolean {
    const rows = document.getElementById('table')['rows'];
    if (rows.length > 3) {
      this.getLoadingHeight(rows);
    }
    return this.config.isLoading;
  }

  getLoadingHeight(rows: any): void {
    const searchEnabled = this.config.searchEnabled ? 1 : 0;
    const headerEnabled = this.config.headerEnabled ? 1 : 0;
    const borderTrHeight = 1;
    const borderDivHeight = 2;
    this.loadingHeight = (rows.length - searchEnabled - headerEnabled) * (rows[3].offsetHeight - borderTrHeight) - borderDivHeight + 'px';
  }

  getColumnWidth(column: any): string {
    if (column.width) {
      return column.width;
    }
    return this.config.fixedColumnWidth ? 100 / this.columns.length + '%' : null;
  }

  onRowDrag(event) {
    this.emitEvent(Event.onRowDrag, event);
  }

  onRowDrop(event) {
    this.emitEvent(Event.onRowDrop, event);
  }
}
